@use '../../variables' as vars;

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .input {
    background-color: vars.$style-input-enabled-background;
    border-radius: 8px;
    height: 56px;
    width: 100%;
    display: flex;
    position: relative;
    border: 1px solid vars.$palette-gray-600;

    &__action {
      border: none;
      background-color: vars.$style-input-enabled-background;
      border-radius: 8px;
      padding: 0px 8px 0 8px;
      display: flex;
      align-items: center;
      svg {
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
      &:active,
      &:focus {
        outline: 2px solid #8678f4;
        z-index: 500;
      }
    }
    &__label {
      color: vars.$scheme-on-background-high;
      position: absolute;
      transition: all 0.2s ease-in-out;
      top: 20px;
      left: 16px;
      line-height: 16px;
      z-index: 5;
      user-select: none;

      &--active {
        top: 6px;
        left: 16px;
        font-size: 12px;
        line-height: 16px;
      }
    }
    input {
      width: 100%;
      border: none;
      z-index: 10;
      font-size: 16px;
      padding: 24px 8px 8px 16px;
      background-color: transparent;
      color: vars.$scheme-on-background-high;
      &:focus {
        outline: none;
      }
      &::-moz-focus-inner {
        border: 0;
      }
    }

    &--filled {
      .input__label {
        color: vars.$style-input-filled-label;
      }
    }

    &--focused {
      border: 2px solid vars.$style-input-focused-indicator;
      .input__label {
        color: vars.$style-input-focused-indicator;
      }
    }

    &--error {
      border: 2px solid vars.$style-input-error-indicator;
      /*  input {
      color: vars.$style-input-error-indicator;
    } */
      .input__label {
        color: vars.$style-input-error-indicator;
      }
    }
    &--success {
      border: 2px solid #27ab83;
      .input__label {
        color: #27ab83;
      }
    }

    &--disabled {
      color: /* vars.$style-input-disabled-content */ red;
      cursor: not-allowed;
      input {
        color: vars.$style-input-disabled-content;
        cursor: not-allowed;
      }
      .input__label {
        color: vars.$style-input-disabled-content;
        cursor: not-allowed;
      }
      .input__action {
        svg {
          cursor: not-allowed;

          path {
            stroke: vars.$style-input-disabled-content;
            fill: vars.$style-input-disabled-content;
          }
        }
      }
    }
  }

  .input__description {
    margin: 2px 8px 16px 8px;
    color: vars.$style-input-enabled-helper-text;
    font-size: 14px;
    &--error {
      color: vars.$style-input-error-indicator;
    }
  }
}

// 1920 1024 360
