@use '../../variables' as vars;

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background-color: vars.$style-background-default;
  //height: 80px;
  color: white;
  padding: 1rem 2rem;
  .copyrigth {
    font-size: 12px;
    line-height: 18px;
  }
  animation: fadein3 2s;
  animation-fill-mode: forwards;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.App--myaccount {
  footer.footer {
    background-color: #000;
  }
}

/* @media (min-width: 640px) {
  .footer {
    background-color: transparent;
  }
} */
