@use '../../variables' as vars;

.wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
  margin: auto 0;

  &--bottom {
    position: absolute;
    bottom: 24px;
    top: auto;
  }

  .banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;

    background: vars.$palette-green-900;
    border: 1px solid vars.$palette-green-500;
    box-shadow: vars.$shadow-400;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    animation: pop-in 0.5s;
    animation-fill-mode: forwards;
    text-align: center;

    &--error {
      background: vars.$style-banner-error-background;
      border: 1px solid vars.$palette-red-500;
    }
  }

  .disappear {
    animation: pop-out 0.5s;
    animation-fill-mode: forwards;
  }
}

.showed {
  opacity: 1;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pop-out {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
