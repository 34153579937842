@use 'sass:map';
@use '../../variables' as vars;

.card {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: vars.$scheme-surface-high;
  width: 100%;
  border-radius: 16px;
  padding: 24px;

  &__header {
    height: 44px;
  }

  &__content {
    flex: 1;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    &__title {
      display: flex;
      align-items: center;
      font-family: map.get(vars.$typography-header-600, 'fontFamily');
      font-size: map.get(vars.$typography-header-600, 'fontSize');
      font-weight: map.get(vars.$typography-header-600, 'fontWeight');
      line-height: map.get(vars.$typography-header-600, 'lineHeight');
      letter-spacing: map.get(vars.$typography-header-600, 'letterSpacing');
      //margin-bottom: map.get(vars.$typography-header-600, 'margin-bottom');
    }
    &__description {
      font-family: map.get(vars.$typography-body-200, 'fontFamily');
      font-size: map.get(vars.$typography-body-200, 'fontSize');
      font-weight: map.get(vars.$typography-body-200, 'fontWeight');
      line-height: map.get(vars.$typography-body-200, 'lineHeight');
      letter-spacing: map.get(vars.$typography-body-200, 'letterSpacing');
      margin-bottom: map.get(vars.$typography-body-200, 'margin-bottom');
    }
    &__findsnlink {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__password-policy-list {
    list-style-type: none;
    color: vars.$style-input-enabled-helper-text;
    margin: 0.5rem 0;

    li {
      margin-bottom: 0.5rem;
      margin-left: 1rem;
      display: flex;
      font-size: 14px;

      span {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 0.5rem;
        svg {
          path {
            fill: vars.$scheme-surface-low;
          }
        }
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    .button {
      flex: 1;
    }
    p {
      text-align: center;
      margin-top: 1rem;
      font-size: 14px;
    }
    .error {
      color: vars.$style-input-error-label;
      margin: 10px;
      text-align: center;
    }
    .message {
      height: 20px;
      margin: 10px;
    }

    .cgu-disclaimer {
      text-align: left;
      font-size: 14px;
      &__link {
        color: vars.$scheme-on-surface-primary;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .forgot-password {
    margin-bottom: 99px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 14px;
  }

  .sign-up {
    margin-bottom: 45px;
  }

  &__cgu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 1rem;
    input {
      margin-right: 0.5rem;
    }
    &__link {
      margin-left: 4px;
    }
  }
  animation: fadeslidein 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.loader {
  width: 50%;
  max-width: 250px;
  margin: 20% auto;
  display: block;
  stroke: vars.$style-button-default-enabled-background;
  circle:last-of-type {
    stroke: vars.$style-button-primary-enabled-background;
  }
}

@media (min-width: 640px) {
  .card {
    width: 360px;
    height: 623px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.45);
    border: 1px solid vars.$scheme-surface-low;
  }
}

@keyframes fadeslidein {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
