.header {
  //display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  //background-color: #353c44;
  //height: 80px;
  color: white;
  padding: 0.5rem 2rem;

  .logo {
    background: none;
    height: auto;
    display: flex;
    align-items: flex-start;

    .separator {
      background-color: white;
      width: 1px;
      height: 25px;
      margin-top: 0.5rem;
      margin-right: 0.7rem;
      margin-left: 0.5rem;
    }

    .product-name {
      padding-top: 0.8rem;
      font-size: 0.7rem;
    }

    svg {
      display: inline-block;
      width: auto;
      height: 2.5rem;
    }
    svg:first-child {
      margin-left: 0px;
      width: 50px;
      display: none;
    }
  }

  button {
    margin: 1rem 22px 1rem 1rem;
  }

  button.button {
    display: none;
  }
}

@media (min-width: 640px) {
  .header {
    display: flex;
  }
}
