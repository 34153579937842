@use 'sass:map';
@use '../../variables' as vars;

.download-confirm {
  height: calc(100vh - 172px);
  flex: 1;
  align-items: 'stretch';
  align-content: 'stretch';
  background-image: url('../../assets/illustration_foreground_associate_success.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  max-height: 530px;
  padding: 0 1.5rem;
  min-height: 510px;
  animation: fadeslidein 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .download-confirm__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;

    color: #ffffff;
    strong {
      color: vars.$scheme-on-surface-primary;
      font-style: normal;
    }

    em {
      position: relative;
      z-index: 100;
      font-weight: 700;
      font-style: normal;
      &::after {
        content: '';
        position: absolute;
        background: #5331b2;
        width: calc(100% + 10px);
        height: 40%;
        top: 50%;
        left: -5px;
        z-index: -1;
      }
    }
  }

  .download-confirm__description {
    font-family: map-get(vars.$typography-body-300, 'fontFamily');
    font-style: map-get(vars.$typography-body-300, 'fontStyle');
    font-weight: map-get(vars.$typography-body-300, 'fontWeight');
    font-size: map-get(vars.$typography-body-300, 'fontSize');
    line-height: map-get(vars.$typography-body-300, 'lineHeight');

    color: #ffffff;
  }
}

@media (min-width: 640px) {
  .download-confirm {
    max-height: 580px;
    &__title,
    &__description {
      width: 320px;
    }
  }
}

@media (min-width: 1024px) {
  .download-confirm {
    max-height: 662px;
    min-height: 550px;
  }
}
