.page404 {
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15vw;

  .title {
    font-size: 1em;
    margin: 0 0 0 0;
    font-weight: bold;
  }
  .content {
    font-size: 0.2em;
  }
  a {
    //text-decoration: none;
    font-size: 0.2em;
  }
  //animation: lost 20s ease-in-out infinite;
}

.App--myaccount {
  .page404 {
    animation: none;
  }
}

@keyframes lost {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.01);
  }
  30% {
    transform: scale(0.01);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@media (min-width: 600px) {
  .page404 {
    margin: auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10rem;

    .title {
      margin: 0 0 0 0;
      font-weight: bold;
    }
    .content {
      font-size: 1rem;
    }
    a {
      font-size: 1rem;
    }
    //animation: lost 20s ease-in-out infinite;
  }

  .App--myaccount {
    .page404 {
      animation: none;
    }
  }

  @keyframes lost {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.01);
    }
    30% {
      transform: scale(0.01);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}
