@use '../../variables' as vars;

.warning {
  font-family: 'Open Sans', sans-serif;
  border-radius: 16px;
  padding: 24px;
  text-transform: uppercase;
  color: vars.$scheme-on-surface-warning;
  background-color: vars.$scheme-surface-medium;
  margin: 20px 1rem 0 1rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  width: calc(100% - 4rem);
}

@media (min-width: 640px) {
  .warning {
    margin: 0 0 16px 0;
    width: 360px;
  }
}

@media (min-width: 1024px) {
  .warning {
    margin: 0 0 16px 0;
    width: auto;
    max-width: calc(1024px - 96px);
  }
}
