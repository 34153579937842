@use 'sass:map';
@use '../../variables' as vars;

.stepscard {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: vars.$scheme-surface-high;
  margin: 0 auto 0 auto;
  //height: calc(100vh - 92px);
  width: 100vw;
  padding: 16px;

  /* &__header {
    margin-bottom: 20px;
  } */

  .top-space {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    flex: 1;
    align-items: stretch;

    &__warning {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      color: vars.$scheme-on-surface-warning;
      text-align: center;
    }
    &__title {
      text-align: center;
      font-size: 20px;
      line-height: 30px;
    }
    &__description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: vars.$scheme-on-surface-medium;
    }

    .stepslist {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .step {
      display: flex;
      flex-direction: column;
      gap: 8px;

      background: vars.$style-surface-medium;
      border: 1px solid vars.$style-surface-low;

      border-radius: 1rem;
      padding: 13px 12px;
      width: 100%;

      font-family: map.get(vars.$typography-header-200, 'fontFamily');
      font-size: map.get(vars.$typography-header-200, 'fontSize');
      font-weight: map.get(vars.$typography-header-200, 'fontWeight');
      line-height: map.get(vars.$typography-header-200, 'lineHeight');
      letter-spacing: map.get(vars.$typography-header-200, 'letterSpacing');
      &__main {
        display: flex;
        align-items: center;
        gap: 8px;
        &__number {
          padding: 8px 15px;
          width: 41px;
          height: 40px;
        }

        &__content {
          display: flex;
          flex-direction: column;
          flex: 1;
          &__title {
            font-size: 18px;
            line-height: 24px;
          }

          &__link {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
          &__helpertext {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: vars.$scheme-on-surface-medium;
          }
        }
      }
    }

    .step-separator {
      display: flex;
      align-items: center;
      height: 24px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      position: relative;
      .separator {
        height: 2px;
        width: 100%;
        background-color: vars.$style-surface-low;
      }
      &__text {
        background-color: vars.$scheme-surface-high;
        position: absolute;
        left: 50%;
        padding: 0 8px;
        transform: translateX(-50%);
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding-top: 20px;
  }

  .forgot-password {
    margin-bottom: 99px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 14px;
  }

  .sign-up {
    margin-bottom: 45px;
  }

  &__cgu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 1rem;
    input {
      margin-right: 0.5rem;
    }
    &__link {
      margin-left: 4px;
    }
  }
  animation: fadeincard 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (min-width: 640px) {
  .stepscard {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    height: auto;
    width: 344px;
    background-color: vars.$scheme-surface-medium;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.45);
  }
}

@keyframes fadeincard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
