@use './variables' as vars;

.App {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  animation: fadein2 2s;
  animation-fill-mode: forwards;
  align-items: center;

  color: vars.$scheme-on-background-high;
  a {
    color: vars.$scheme-on-surface-primary;
    &:hover {
      color: vars.$scheme-on-surface-primary;
    }
  }

  .primary-button {
    border: none;
    color: white;
    padding: 1rem 2rem;
    background-color: vars.$style-button-primary-enabled-background;
    text-transform: uppercase;
    font-weight: bold;

    border-radius: 5px;
    margin: 2px;
    &:hover {
      background-color: vars.$style-button-primary-hovered-background;
      cursor: pointer;
    }
    &:disabled {
      background-color: vars.$style-button-primary-disabled-background;
      cursor: initial;
    }
  }

  .error {
    color: vars.$style-input-error-label;
    margin: 0 10px 10px 10px;
    height: 2rem;
    line-height: 1rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
  .success {
    color: green;
    margin: 10px;
  }
  .passworderror {
    color: vars.$style-input-error-label;
    //font-size: 0.7rem;
    text-align: left;
    margin: auto;
    font-weight: bold;
  }
}

.layout {
  display: flex;
  flex: 1;
  width: 100%;
}

@function text-color($color) {
  @if lightness($color) > 50% {
    @return #000;
  } @else {
    @return #fff;
  }
}

:root {
  --amplify-components-button-primary-background-color: #341e7a;
  --amplify-components-button-primary-hover-background-color: #341e7a;
  --amplify-components-button-primary-active-background-color: #341e7a;
  --amplify-components-button-primary-focus-background-color: #341e7a;
  --amplify-colors-border-focus: #341e7a;
  --amplify-components-select-color: red;
  margin: 0;
  padding: 0;
}

/* @media (min-width: 550px) {
  .controls {
    position: fixed;
    top: 3px;
    right: 0px;
    padding: 0.5rem;
    display: inline-flex;

    .language-changer {
      position: static;
      padding: 0 10px 0 0;
    }
    .sign-out {
      display: inline-block;
      padding-top: 5px;
    }
  }
}
 */
@keyframes fadein3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein4 {
  from {
    background-color: #06070a;
  }
  to {
    background-color: vars.$style-surface-high;
  }
}

@keyframes fadein2 {
  from {
    background-color: #06070a;
    opacity: 0;
  }
  to {
    background-color: transparent;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    background-color: #06070a;
  }
  to {
    background-color: transparent;
  }
}

.fadebackground {
  height: 100vh;
  background-color: #06070a;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.fadebackgroundLoaded {
  animation: fadein 2s;
  animation-fill-mode: forwards;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

a:focus {
  outline: 2px solid #341e7a;
  border-radius: 5px;
}

.signin {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px vars.$style-input-enabled-background
      inset;
    box-shadow: 0 0 0px 1000px vars.$style-input-enabled-background inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
    -webkit-text-size-adjust: 16px;
    //padding: 16px 0 16px 0;
  }
}

@media (min-width: 640px) {
  .App {
    .layout {
      display: flex;
      margin: auto;
      flex: 1;
      align-items: center;
      justify-content: center;
      //width: 456px;
      padding: 0 48px;
      background-color: #06070a;
      background-image: url('./assets/illustration_background_stars_night_beam.png');
      background-position: 100% 0;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media (min-width: 1024px) {
  .App {
    .layout.common {
      display: flex;
      max-width: 1024px;
      margin: auto;
      flex: 1;
      align-items: center;
      width: 100%;
    }
  }
}
