@use 'sass:map';
@use '../../variables' as vars;

.not-mobile {
  display: none;
}

@media (min-width: 1024px) {
  .mobile {
    display: none;
  }
  .not-mobile {
    display: flex;
  }
}

.check-sn {
  display: flex;
  align-items: center;
  gap: 8px;

  background: vars.$style-surface-medium;
  border-radius: 16px;
  border: 1px solid vars.$style-surface-low;
  padding: 13px 12px;
  width: 100%;

  font-family: map.get(vars.$typography-header-300, 'fontFamily');
  font-size: map.get(vars.$typography-header-300, 'fontSize');
  font-weight: map.get(vars.$typography-header-300, 'fontWeight');
  line-height: map.get(vars.$typography-header-300, 'lineHeight');
  letter-spacing: map.get(vars.$typography-header-300, 'letterSpacing');

  .sn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 8px 16px;
    gap: 8px;

    background: vars.$style-surface-high;
    border-radius: 8px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-transform: lowercase;

    color: #ffffff;
  }

  .step__main__content__title {
    margin: auto;
    text-align: center;
  }
}

.howto {
  padding: 1rem;
}
