// Do not edit directly
// Generated on Thu, 02 Mar 2023 17:08:36 GMT

$font-sizes-050: 0.75rem;
$font-sizes-800: 2.25rem;
$font-sizes-700: 1.875rem;
$font-sizes-600: 1.75rem;
$font-sizes-500: 1.625rem;
$font-sizes-400: 1.25rem;
$font-sizes-300: 1.125rem;
$font-sizes-200: 1rem;
$font-sizes-100: 0.875rem;
$paragraph-spacing-050: 0.25rem;
$paragraph-spacing-300: 1rem;
$paragraph-spacing-200: 0.75rem;
$paragraph-spacing-100: 0.5rem;
$paragraph-spacing-0: 0rem;
$letter-spacing-0: 0%;
$font-weights-low: 400;
$font-weights-medium: 600;
$font-weights-high: 700;
$font-families-default: 'Open Sans';
$sizing-small: 0.25rem;
$sizing-medium: 0.5rem;
$sizing-large: 1rem;
$line-heights-050: 1.125rem;
$line-heights-800: 3rem;
$line-heights-700: 2.625rem;
$line-heights-600: 2.25rem;
$line-heights-500: 2.25rem;
$line-heights-450: 1.875rem;
$line-heights-400: 1.625rem;
$line-heights-300: 1.5rem;
$line-heights-200: 1.375rem;
$line-heights-100: 1.25rem;
$gradient-type: gradient;
$shadow-400: 0px 0px 16px 0px rgba(0, 0, 0, 0.45);
$shadow-100: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
$palette-black: #000000;
$palette-transparent: #ffffff00;
$palette-dark-yellow-050: #836608;
$palette-dark-yellow-900: #231915;
$palette-dark-yellow-800: #2d1f18;
$palette-dark-yellow-700: #37251b;
$palette-dark-yellow-600: #412c1c;
$palette-dark-yellow-500: #4b321b;
$palette-dark-yellow-400: #553a1a;
$palette-dark-yellow-300: #5f4218;
$palette-dark-yellow-200: #6b4d14;
$palette-dark-yellow-100: #74570f;
$palette-yellow-050: #fffbea;
$palette-yellow-900: #a44010;
$palette-yellow-800: #bb6013;
$palette-yellow-700: #cb6e17;
$palette-yellow-600: #de911d;
$palette-yellow-500: #f0b429;
$palette-yellow-400: #f7c948;
$palette-yellow-300: #fadb5f;
$palette-yellow-200: #fce588;
$palette-yellow-100: #fff3c4;
$palette-dark-green-050: #1f5230;
$palette-dark-green-900: #09180e;
$palette-dark-green-800: #0c1f12;
$palette-dark-green-700: #0e2616;
$palette-dark-green-600: #112d1a;
$palette-dark-green-500: #13341e;
$palette-dark-green-400: #163a22;
$palette-dark-green-300: #184025;
$palette-dark-green-200: #1a4629;
$palette-dark-green-100: #1c4c2c;
$palette-green-050: #effcf6;
$palette-green-900: #014d40;
$palette-green-800: #0c6b58;
$palette-green-700: #147d64;
$palette-green-600: #199473;
$palette-green-500: #27ab83;
$palette-green-400: #3ebd93;
$palette-green-300: #65d6ad;
$palette-green-200: #8eedc7;
$palette-green-100: #c6f7e2;
$palette-dark-red-050: #6e2525;
$palette-dark-red-900: #13060a;
$palette-dark-red-800: #1d0a0e;
$palette-dark-red-700: #270d12;
$palette-dark-red-600: #311016;
$palette-dark-red-500: #3b1319;
$palette-dark-red-400: #45161c;
$palette-dark-red-300: #4f1a1e;
$palette-dark-red-200: #591d21;
$palette-dark-red-100: #632022;
$palette-red-050: #fee9e9;
$palette-red-900: #f62121;
$palette-red-800: #f83030;
$palette-red-700: #f93838;
$palette-red-600: #f94141;
$palette-red-500: #fa4848;
$palette-red-400: #fb6363;
$palette-red-300: #fc7f7f;
$palette-red-200: #fda4a4;
$palette-red-100: #fec8c8;
$palette-pink-050: #fdedf0;
$palette-pink-900: #e13a50;
$palette-pink-800: #e44a62;
$palette-pink-700: #e7546c;
$palette-pink-600: #ea5f77;
$palette-pink-500: #ec677f;
$palette-pink-400: #ef7e92;
$palette-pink-300: #f295a5;
$palette-pink-200: #f6b3bf;
$palette-pink-100: #f9d1d9;
$palette-dark-purple-050: #1b0a59;
$palette-dark-purple-900: #020102;
$palette-dark-purple-800: #0b080c;
$palette-dark-purple-700: #130e15;
$palette-dark-purple-600: #1a111f;
$palette-dark-purple-500: #1f1429;
$palette-dark-purple-400: #221532;
$palette-dark-purple-300: #22143c;
$palette-dark-purple-200: #201246;
$palette-dark-purple-100: #1a0f50;
$palette-purple-050: #edeffd;
$palette-purple-900: #110728;
$palette-purple-800: #1f0f4c;
$palette-purple-700: #2c1667;
$palette-purple-600: #341e7a;
$palette-purple-500: #5331b2;
$palette-purple-400: #7b53ea;
$palette-purple-300: #8678f4;
$palette-purple-200: #9ea1f9;
$palette-purple-100: #ced2fa;
$palette-dark-gray-050: #333638;
$palette-dark-gray-900: #000000;
$palette-dark-gray-800: #060606;
$palette-dark-gray-700: #0c0c0d;
$palette-dark-gray-600: #111213;
$palette-dark-gray-500: #171819;
$palette-dark-gray-400: #1d1e1f;
$palette-dark-gray-300: #222426;
$palette-dark-gray-200: #282a2c;
$palette-dark-gray-100: #2e3032;
$palette-gray-050: #ffffff;
$palette-gray-900: #06070a;
$palette-gray-800: #0f1418;
$palette-gray-700: #161c21;
$palette-gray-600: #1f262b;
$palette-gray-500: #4a525b;
$palette-gray-400: #969da5;
$palette-gray-300: #b9bdc2;
$palette-gray-200: #d1d3d7;
$palette-gray-100: #e8e8eb;
$typography-link-100: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-100,
  'fontSize': $font-sizes-100,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-0,
);
$typography-action-300: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-medium,
  'lineHeight': $line-heights-300,
  'fontSize': $font-sizes-300,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-0,
);
$typography-action-100: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-medium,
  'lineHeight': $line-heights-100,
  'fontSize': $font-sizes-100,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-0,
);
$typography-header-050: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-050,
  'fontSize': $font-sizes-050,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-050,
);
$typography-header-800: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-800,
  'fontSize': $font-sizes-800,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-header-700: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-700,
  'fontSize': $font-sizes-700,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-header-600: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-600,
  'fontSize': $font-sizes-600,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-header-500: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-600,
  'fontSize': $font-sizes-500,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-header-400: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-450,
  'fontSize': $font-sizes-400,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-header-300: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-400,
  'fontSize': $font-sizes-300,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-header-200: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-300,
  'fontSize': $font-sizes-200,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-050,
);
$typography-header-100: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-high,
  'lineHeight': $line-heights-200,
  'fontSize': $font-sizes-100,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-050,
);
$typography-body-050: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-050,
  'fontSize': $font-sizes-050,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-050,
);
$typography-body-500: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-500,
  'fontSize': $font-sizes-500,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-200,
);
$typography-body-400: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-450,
  'fontSize': $font-sizes-400,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-200,
);
$typography-body-300: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-400,
  'fontSize': $font-sizes-300,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-body-200: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-200,
  'fontSize': $font-sizes-200,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-100,
);
$typography-body-100: (
  'fontFamily': $font-families-default,
  'fontWeight': $font-weights-low,
  'lineHeight': $line-heights-100,
  'fontSize': $font-sizes-100,
  'letterSpacing': $letter-spacing-0,
  'margin-bottom': $paragraph-spacing-050,
);
$style-image-item-actived-off-counter-value: $palette-transparent;
$style-image-item-enabled-counter-value: $palette-transparent;
$style-image-item-enabled-counter-border: $palette-transparent;
$style-image-item-enabled-counter-background: $palette-transparent;
$style-tab-item-unselected-indicator: $palette-transparent;
$style-tab-icon-actived-border: $palette-transparent;
$style-tab-icon-disabled-border: $palette-transparent;
$style-tab-icon-enabled-border: $palette-transparent;
$style-radio-button-ios-disabled-off-background: $palette-transparent;
$style-radio-button-ios-disabled-off-icon: $palette-transparent;
$style-radio-button-ios-enabled-off-background: $palette-transparent;
$style-radio-button-ios-enabled-off-icon: $palette-transparent;
$style-radio-button-disabled-off-knob: $palette-transparent;
$style-radio-button-enabled-off-knob: $palette-transparent;
$style-checkbox-disabled-off-background: $palette-transparent;
$style-checkbox-enabled-off-background: $palette-transparent;
$style-appbar-page-transparent-background: $palette-transparent;
$style-zoom-bar-item-enabled-background: $palette-transparent;
$style-navigation-item-disabled-background: $palette-transparent;
$style-navigation-item-enabled-background: $palette-transparent;
$style-input-displayed-indicator: $palette-transparent;
$style-chips-action-default-disabled-border: $palette-transparent;
$style-chips-action-default-enabled-border: $palette-transparent;
$style-chips-action-primary-disabled-border: $palette-transparent;
$style-chips-action-primary-enabled-border: $palette-transparent;
$style-card-disabled-border: $palette-transparent;
$style-card-enabled-border: $palette-transparent;
$style-button-facultative-actived-background: $palette-transparent;
$style-button-facultative-disabled-background: $palette-transparent;
$style-button-facultative-enabled-background: $palette-transparent;
$scheme-on-warning-disabled: $palette-gray-400;
$scheme-on-warning-high: $palette-gray-900;
$scheme-warning-disabled: $palette-yellow-800;
$scheme-warning-high: $palette-yellow-500;
$scheme-on-success-disabled: $palette-gray-400;
$scheme-on-success-high: $palette-gray-050;
$scheme-success-disabled: $palette-green-800;
$scheme-success-high: $palette-green-500;
$scheme-on-error-disabled: $palette-gray-400;
$scheme-on-error-high: $palette-gray-900;
$scheme-error-disabled: $palette-gray-700;
$scheme-error-high: $palette-red-400;
$scheme-on-surface-disabled: $palette-gray-400;
$scheme-on-surface-warning: $palette-yellow-500;
$scheme-on-surface-success: $palette-green-500;
$scheme-on-surface-error: $palette-red-400;
$scheme-on-surface-primary: $palette-purple-300;
$scheme-on-surface-medium: $palette-gray-300;
$scheme-on-surface-high: $palette-gray-050;
$scheme-surface-disabled: $palette-gray-700;
$scheme-surface-low: $palette-gray-600;
$scheme-surface-medium: $palette-gray-700;
$scheme-surface-high: $palette-gray-800;
$scheme-on-background-primary: $palette-purple-200;
$scheme-on-background-high: $palette-gray-050;
$scheme-background: $palette-gray-900;
$scheme-on-primary-disabled: $palette-purple-300;
$scheme-on-primary-high: $palette-gray-050;
$scheme-primary-disabled: $palette-purple-900;
$scheme-primary-high: $palette-purple-500;
$scheme-primary-medium: $palette-purple-800;
$scheme-primary-low: $palette-purple-900;
$theme-light-on-success-disabled: $palette-gray-400;
$theme-light-on-success-high: $palette-gray-050;
$theme-light-on-error-disabled: $palette-gray-400;
$theme-light-on-error-high: $palette-gray-900;
$theme-light-on-surface-disabled: $palette-gray-400;
$theme-light-on-surface-warning: $palette-yellow-500;
$theme-light-on-surface-success: $palette-green-500;
$theme-light-on-surface-error: $palette-red-400;
$theme-light-on-surface-primary: $palette-purple-500;
$theme-light-on-surface-medium: $palette-gray-700;
$theme-light-on-surface-high: $palette-gray-900;
$theme-light-on-background-primary: $palette-purple-500;
$theme-light-on-background-high: $palette-gray-900;
$theme-light-on-primary-disabled: $palette-purple-300;
$theme-light-on-primary-high: $palette-gray-050;
$theme-light-on-warning-disabled: $palette-gray-400;
$theme-light-on-warning-high: $palette-gray-900;
$theme-light-warning-disabled: $palette-yellow-200;
$theme-light-warning-high: $palette-yellow-400;
$theme-light-success-disabled: $palette-green-200;
$theme-light-success-high: $palette-green-300;
$theme-light-error-disabled: $palette-red-200;
$theme-light-error-high: $palette-red-700;
$theme-light-surface-disabled: $palette-gray-400;
$theme-light-surface-low: $palette-gray-300;
$theme-light-surface-medium: $palette-gray-200;
$theme-light-surface-high: $palette-gray-100;
$theme-light-background: $palette-gray-050;
$theme-light-primary-disabled: $palette-purple-900;
$theme-light-primary-high: $palette-purple-500;
$theme-light-primary-medium: $palette-purple-300;
$theme-light-primary-low: $palette-purple-200;
$theme-astro-on-error-disabled: $palette-dark-red-400;
$theme-astro-on-error-high: $palette-dark-red-200;
$theme-astro-on-warning-disabled: $palette-red-400;
$theme-astro-on-warning-high: $palette-red-900;
$theme-astro-warning-disabled: $palette-yellow-800;
$theme-astro-warning-high: $palette-yellow-500;
$theme-astro-on-success-disabled: $palette-dark-red-400;
$theme-astro-on-success-high: $palette-dark-red-050;
$theme-astro-success-disabled: $palette-dark-gray-600;
$theme-astro-success-high: $palette-dark-gray-400;
$theme-astro-error-disabled: $palette-dark-gray-700;
$theme-astro-error-high: $palette-red-900;
$theme-astro-on-primary-disabled: $palette-dark-red-100;
$theme-astro-on-primary-high: $palette-red-900;
$theme-astro-primary-disabled: $palette-dark-gray-800;
$theme-astro-primary-high: $palette-dark-red-200;
$theme-astro-primary-medium: $palette-dark-red-400;
$theme-astro-primary-low: $palette-dark-red-500;
$theme-astro-on-surface-disabled: $palette-dark-red-400;
$theme-astro-on-surface-warning: $palette-dark-yellow-300;
$theme-astro-on-surface-success: $palette-dark-yellow-050;
$theme-astro-on-surface-error: $palette-dark-yellow-600;
$theme-astro-on-surface-primary: $palette-red-300;
$theme-astro-on-surface-medium: $palette-dark-red-050;
$theme-astro-on-surface-high: $palette-red-900;
$theme-astro-surface-disabled: $palette-dark-gray-400;
$theme-astro-surface-low: $palette-dark-gray-500;
$theme-astro-surface-medium: $palette-dark-gray-600;
$theme-astro-surface-high: $palette-dark-gray-700;
$theme-astro-on-background-primary: $palette-dark-purple-700;
$theme-astro-on-background-high: $palette-dark-red-050;
$theme-astro-background: $palette-dark-gray-900;
$gradient-background-top: linear-gradient(
  0deg,
  rgba($scheme-background, 0) 0%,
  rgba($scheme-background, 0.6) 25%,
  rgba($scheme-background, 1) 100%
);
$gradient-background-bottom: linear-gradient(
  90deg,
  rgba($scheme-background, 0) 0%,
  rgba($scheme-background, 0.6) 75%,
  rgba($scheme-background, 1) 100%
);
$style-menu-enabled-scroll-bar: $scheme-surface-low;
$style-menu-enabled-background: $scheme-surface-high;
$style-stepper-enabled-tick: $scheme-on-surface-medium;
$style-stepper-enabled-selected-tick: $scheme-on-surface-high;
$style-image-item-actived-off-counter-border: $scheme-on-surface-high;
$style-image-item-actived-off-counter-background: $scheme-surface-high;
$style-image-item-actived-off-background: $scheme-surface-medium;
$style-image-item-actived-on-counter-value: $scheme-on-surface-high;
$style-image-item-actived-on-counter-border: $scheme-on-surface-high;
$style-image-item-actived-on-counter-background: $scheme-surface-high;
$style-image-item-actived-on-background: $scheme-surface-medium;
$style-image-item-enabled-background: $scheme-surface-medium;
$style-tab-item-unselected-background: $scheme-surface-high;
$style-tab-item-unselected-label: $scheme-on-surface-high;
$style-tab-item-unselected-icon: $scheme-on-surface-medium;
$style-tab-item-selected-background: $scheme-surface-high;
$style-tab-item-selected-indicator: $scheme-on-surface-high;
$style-tab-item-selected-label: $scheme-on-surface-high;
$style-tab-item-selected-icon: $scheme-on-surface-high;
$style-tab-icon-actived-selected-border: $scheme-on-surface-medium;
$style-tab-icon-actived-selected-content: $scheme-on-surface-high;
$style-tab-icon-actived-selected-background: $scheme-primary-high;
$style-tab-icon-actived-selected-icon: $scheme-on-primary-high;
$style-tab-icon-actived-content: $scheme-on-surface-high;
$style-tab-icon-actived-background: $scheme-primary-high;
$style-tab-icon-actived-icon: $scheme-on-primary-high;
$style-tab-icon-disabled-selected-border: $scheme-on-surface-medium;
$style-tab-icon-disabled-selected-content: $scheme-on-surface-disabled;
$style-tab-icon-disabled-selected-background: $scheme-surface-low;
$style-tab-icon-disabled-selected-icon: $scheme-on-surface-disabled;
$style-tab-icon-disabled-content: $scheme-on-surface-disabled;
$style-tab-icon-disabled-background: $scheme-surface-low;
$style-tab-icon-disabled-icon: $scheme-on-surface-disabled;
$style-tab-icon-enabled-selected-border: $scheme-on-surface-high;
$style-tab-icon-enabled-selected-content: $scheme-on-surface-high;
$style-tab-icon-enabled-selected-background: $scheme-primary-low;
$style-tab-icon-enabled-selected-icon: $scheme-on-primary-high;
$style-tab-icon-enabled-content: $scheme-on-surface-high;
$style-tab-icon-enabled-background: $scheme-surface-medium;
$style-tab-icon-enabled-icon: $scheme-on-surface-high;
$style-slider-enabled-graduation: $scheme-on-surface-disabled;
$style-slider-enabled-indicator: $scheme-primary-high;
$style-slider-enabled-cursor: $scheme-on-surface-primary;
$style-slider-enabled-rail: $scheme-surface-low;
$style-slider-enabled-knob: $scheme-on-surface-high;
$style-joystick-disabled-knob: $scheme-on-surface-disabled;
$style-joystick-disabled-background: $scheme-surface-medium;
$style-joystick-enabled-knob: $scheme-on-surface-medium;
$style-joystick-enabled-background: $scheme-surface-medium;
$style-radio-button-ios-disabled-off-border: $scheme-on-surface-disabled;
$style-radio-button-ios-disabled-on-border: $scheme-primary-disabled;
$style-radio-button-ios-disabled-on-background: $scheme-primary-disabled;
$style-radio-button-ios-disabled-on-icon: $scheme-on-primary-disabled;
$style-radio-button-ios-enabled-off-border: $scheme-on-surface-primary;
$style-radio-button-ios-enabled-on-border: $scheme-primary-high;
$style-radio-button-ios-enabled-on-background: $scheme-primary-high;
$style-radio-button-ios-enabled-on-icon: $scheme-on-primary-high;
$style-radio-button-disabled-off-border: $scheme-on-surface-disabled;
$style-radio-button-disabled-on-border: $scheme-on-surface-disabled;
$style-radio-button-disabled-on-knob: $scheme-on-surface-disabled;
$style-radio-button-enabled-off-border: $scheme-on-surface-primary;
$style-radio-button-enabled-on-border: $scheme-on-surface-primary;
$style-radio-button-enabled-on-knob: $scheme-on-surface-primary;
$style-checkbox-disabled-off-border: $scheme-on-surface-disabled;
$style-checkbox-disabled-on-icon: $scheme-on-surface-disabled;
$style-checkbox-disabled-on-background: $scheme-surface-disabled;
$style-checkbox-enabled-off-border: $scheme-on-surface-primary;
$style-checkbox-enabled-on-icon: $scheme-on-primary-high;
$style-checkbox-enabled-on-background: $scheme-primary-high;
$style-list-action-disabled-background: $scheme-surface-medium;
$style-list-action-disabled-icon: $scheme-on-surface-disabled;
$style-list-action-disabled-label: $scheme-on-surface-disabled;
$style-list-action-enabled-background: $scheme-surface-high;
$style-list-action-enabled-icon: $scheme-on-surface-high;
$style-list-action-enabled-label: $scheme-on-surface-high;
$style-list-row-enabled-divider: $scheme-surface-low;
$style-list-row-enabled-prefix: $scheme-on-surface-disabled;
$style-list-row-enabled-label: $scheme-on-surface-high;
$style-progress-indicator-dark-indicator-high: $scheme-on-surface-success;
$style-progress-indicator-dark-indicator-medium: $scheme-on-surface-warning;
$style-progress-indicator-dark-indicator-low: $scheme-on-surface-error;
$style-progress-indicator-dark-indicator: $scheme-on-background-primary;
$style-progress-indicator-dark-rail: $scheme-background;
$style-progress-indicator-light-indicator-high: $scheme-on-surface-success;
$style-progress-indicator-light-indicator-medium: $scheme-on-surface-warning;
$style-progress-indicator-light-indicator-low: $scheme-on-surface-error;
$style-progress-indicator-light-indicator: $scheme-on-surface-primary;
$style-progress-indicator-light-rail: $scheme-surface-high;
$style-input-search-enabled-placeholder: $scheme-on-surface-disabled;
$style-input-comment-enabled-background: $scheme-surface-high;
$style-switch-disabled-off-knob: $scheme-on-success-disabled;
$style-switch-disabled-off-rail: $scheme-surface-medium;
$style-switch-disabled-on-knob: $scheme-on-success-disabled;
$style-switch-disabled-on-rail: $scheme-success-disabled;
$style-switch-enabled-off-knob: $scheme-on-surface-high;
$style-switch-enabled-off-rail: $scheme-surface-medium;
$style-switch-enabled-on-knob: $scheme-on-success-high;
$style-switch-enabled-on-rail: $scheme-success-high;
$style-appbar-sheet-label-ios: $scheme-on-surface-primary;
$style-appbar-sheet-handle: $scheme-surface-low;
$style-appbar-sheet-icon: $scheme-on-surface-high;
$style-appbar-sheet-title: $scheme-on-surface-high;
$style-appbar-sheet-background: $scheme-surface-high;
$style-appbar-page-transparent-label-ios: $scheme-on-background-primary;
$style-appbar-page-transparent-title: $scheme-on-background-high;
$style-appbar-page-filled-label-ios: $scheme-on-background-primary;
$style-appbar-page-filled-title: $scheme-on-background-high;
$style-zoom-bar-item-selected-content: $scheme-on-surface-high;
$style-zoom-bar-item-selected-background: $scheme-surface-low;
$style-zoom-bar-item-enabled-content: $scheme-on-surface-high;
$style-navigation-item-actived-content: $scheme-on-surface-primary;
$style-navigation-item-actived-background: $scheme-surface-high;
$style-navigation-item-disabled-content: $scheme-on-surface-disabled;
$style-navigation-item-enabled-content: $scheme-on-surface-high;
$style-banner-success-content: $scheme-on-success-high;
$style-banner-success-background: $scheme-success-high;
$style-banner-error-content: $scheme-on-error-high;
$style-banner-error-background: $scheme-error-high;
$style-banner-default-content: $scheme-on-surface-high;
$style-banner-default-background: $scheme-surface-medium;
$style-snackbar-default-content: $scheme-on-surface-high;
$style-snackbar-default-background: $scheme-surface-medium;
$style-input-displayed-helper-text: $scheme-on-surface-medium;
$style-input-displayed-counter: $scheme-on-surface-medium;
$style-input-displayed-cursor: $scheme-on-surface-primary;
$style-input-displayed-suffix: $scheme-on-surface-medium;
$style-input-displayed-scroll-bar: $scheme-surface-low;
$style-input-displayed-label: $scheme-on-surface-high;
$style-input-displayed-placeholder: $scheme-on-surface-disabled;
$style-input-displayed-content: $scheme-on-surface-high;
$style-input-displayed-background: $scheme-background;
$style-input-error-helper-text: $scheme-on-surface-error;
$style-input-error-counter: $scheme-on-surface-medium;
$style-input-error-cursor: $scheme-on-surface-primary;
$style-input-error-suffix: $scheme-on-surface-medium;
$style-input-error-scroll-bar: $scheme-surface-low;
$style-input-error-indicator: $scheme-on-surface-error;
$style-input-error-label: $scheme-on-surface-error;
$style-input-error-placeholder: $scheme-on-surface-disabled;
$style-input-error-content: $scheme-on-surface-high;
$style-input-error-background: $scheme-background;
$style-input-filled-border: $scheme-surface-medium;
$style-input-filled-helper-text: $scheme-on-surface-medium;
$style-input-filled-counter: $scheme-on-surface-medium;
$style-input-filled-suffix: $scheme-on-surface-medium;
$style-input-filled-scroll-bar: $scheme-surface-low;
$style-input-filled-indicator: $scheme-surface-low;
$style-input-filled-label: $scheme-on-surface-medium;
$style-input-filled-content: $scheme-on-surface-high;
$style-input-filled-background: $scheme-background;
$style-input-focused-helper-text: $scheme-on-surface-medium;
$style-input-focused-counter: $scheme-on-surface-medium;
$style-input-focused-border: $scheme-on-surface-primary;
$style-input-focused-cursor: $scheme-on-surface-primary;
$style-input-focused-suffix: $scheme-on-surface-medium;
$style-input-focused-scroll-bar: $scheme-surface-low;
$style-input-focused-indicator: $scheme-primary-high;
$style-input-focused-label: $scheme-on-surface-primary;
$style-input-focused-placeholder: $scheme-on-surface-disabled;
$style-input-focused-content: $scheme-on-surface-high;
$style-input-focused-background: $scheme-background;
$style-input-disabled-border: $scheme-surface-medium;
$style-input-disabled-helper-text: $scheme-on-surface-medium;
$style-input-disabled-counter: $scheme-on-surface-medium;
$style-input-disabled-suffix: $scheme-on-surface-disabled;
$style-input-disabled-indicator: $scheme-surface-disabled;
$style-input-disabled-content: $scheme-on-surface-disabled;
$style-input-disabled-background: $scheme-background;
$style-input-enabled-border: $scheme-surface-low;
$style-input-enabled-helper-text: $scheme-on-surface-medium;
$style-input-enabled-counter: $scheme-on-surface-medium;
$style-input-enabled-suffix: $scheme-on-surface-medium;
$style-input-enabled-indicator: $scheme-surface-low;
$style-input-enabled-content: $scheme-on-surface-high;
$style-input-enabled-background: $scheme-background;
$style-chips-choice-light-selected-disabled-border: $scheme-primary-disabled;
$style-chips-choice-light-selected-disabled-icon: $scheme-on-primary-disabled;
$style-chips-choice-light-selected-disabled-label: $scheme-on-primary-disabled;
$style-chips-choice-light-selected-disabled-background: $scheme-primary-disabled;
$style-chips-choice-light-selected-enabled-border: $scheme-primary-high;
$style-chips-choice-light-selected-enabled-icon: $scheme-on-primary-high;
$style-chips-choice-light-selected-enabled-label: $scheme-on-primary-high;
$style-chips-choice-light-selected-enabled-background: $scheme-primary-high;
$style-chips-choice-light-unselected-disabled-border: $scheme-primary-disabled;
$style-chips-choice-light-unselected-disabled-icon: $scheme-on-surface-disabled;
$style-chips-choice-light-unselected-disabled-label: $scheme-on-surface-disabled;
$style-chips-choice-light-unselected-disabled-background: $scheme-surface-disabled;
$style-chips-choice-light-unselected-enabled-border: $scheme-primary-high;
$style-chips-choice-light-unselected-enabled-icon: $scheme-on-surface-high;
$style-chips-choice-light-unselected-enabled-label: $scheme-on-surface-high;
$style-chips-choice-light-unselected-enabled-background: $scheme-surface-high;
$style-chips-choice-dark-selected-disabled-border: $scheme-primary-disabled;
$style-chips-choice-dark-selected-disabled-icon: $scheme-on-primary-disabled;
$style-chips-choice-dark-selected-disabled-label: $scheme-on-primary-disabled;
$style-chips-choice-dark-selected-disabled-background: $scheme-primary-disabled;
$style-chips-choice-dark-selected-enabled-border: $scheme-primary-high;
$style-chips-choice-dark-selected-enabled-icon: $scheme-on-primary-high;
$style-chips-choice-dark-selected-enabled-label: $scheme-on-primary-high;
$style-chips-choice-dark-selected-enabled-background: $scheme-primary-high;
$style-chips-choice-dark-unselected-disabled-border: $scheme-primary-disabled;
$style-chips-choice-dark-unselected-disabled-icon: $scheme-on-surface-disabled;
$style-chips-choice-dark-unselected-disabled-label: $scheme-on-surface-disabled;
$style-chips-choice-dark-unselected-disabled-background: $scheme-surface-disabled;
$style-chips-choice-dark-unselected-enabled-border: $scheme-primary-high;
$style-chips-choice-dark-unselected-enabled-icon: $scheme-on-surface-high;
$style-chips-choice-dark-unselected-enabled-label: $scheme-on-surface-high;
$style-chips-choice-dark-unselected-enabled-background: $scheme-background;
$style-chips-action-default-disabled-icon: $scheme-on-surface-disabled;
$style-chips-action-default-disabled-label: $scheme-on-surface-disabled;
$style-chips-action-default-disabled-background: $scheme-surface-disabled;
$style-chips-action-default-enabled-icon: $scheme-on-surface-high;
$style-chips-action-default-enabled-label: $scheme-on-surface-high;
$style-chips-action-default-enabled-background: $scheme-surface-low;
$style-chips-action-primary-disabled-icon: $scheme-on-primary-disabled;
$style-chips-action-primary-disabled-label: $scheme-on-primary-disabled;
$style-chips-action-primary-disabled-background: $scheme-primary-disabled;
$style-chips-action-primary-enabled-icon: $scheme-on-primary-high;
$style-chips-action-primary-enabled-label: $scheme-on-primary-high;
$style-chips-action-primary-enabled-background: $scheme-primary-medium;
$style-card-disabled-description: $scheme-on-surface-disabled;
$style-card-disabled-icon: $scheme-on-surface-disabled;
$style-card-disabled-label: $scheme-on-surface-disabled;
$style-card-disabled-background: $scheme-surface-high;
$style-card-selected-description: $scheme-on-surface-high;
$style-card-selected-icon: $scheme-on-surface-high;
$style-card-selected-label: $scheme-on-surface-high;
$style-card-selected-border: $scheme-primary-high;
$style-card-selected-background: $scheme-surface-medium;
$style-card-enabled-description: $scheme-on-surface-high;
$style-card-enabled-icon: $scheme-on-surface-high;
$style-card-enabled-label: $scheme-on-surface-high;
$style-card-enabled-background: $scheme-surface-medium;
$style-button-error-hovered-content: $scheme-on-surface-high;
$style-button-error-hovered-background: $scheme-surface-medium;
$style-button-error-disabled-content: $scheme-on-error-disabled;
$style-button-error-disabled-background: $scheme-error-disabled;
$style-button-error-enabled-content: $scheme-on-surface-error;
$style-button-error-enabled-background: $scheme-surface-low;
$style-button-facultative-hovered-content: $scheme-on-primary-high;
$style-button-facultative-hovered-background: $scheme-surface-medium;
$style-button-facultative-actived-content: $scheme-on-surface-primary;
$style-button-facultative-pressed-content: $scheme-on-surface-disabled;
$style-button-facultative-pressed-background: $scheme-surface-high;
$style-button-facultative-disabled-content: $scheme-on-surface-disabled;
$style-button-facultative-enabled-content: $scheme-on-surface-high;
$style-button-default-hovered-content: $scheme-on-primary-high;
$style-button-default-hovered-background: $scheme-primary-medium;
$style-button-default-actived-content: $scheme-on-primary-high;
$style-button-default-actived-background: $scheme-primary-low;
$style-button-default-disabled-content: $scheme-on-surface-disabled;
$style-button-default-disabled-background: $scheme-surface-disabled;
$style-button-default-enabled-content: $scheme-on-surface-high;
$style-button-default-enabled-background: $scheme-surface-low;
$style-button-primary-hovered-content: $scheme-on-primary-high;
$style-button-primary-hovered-background: $scheme-primary-medium;
$style-button-primary-pressed-content: $scheme-on-primary-high;
$style-button-primary-pressed-background: $scheme-primary-low;
$style-button-primary-disabled-content: $scheme-on-primary-disabled;
$style-button-primary-disabled-background: $scheme-primary-disabled;
$style-button-primary-enabled-content: $scheme-on-primary-high;
$style-button-primary-enabled-background: $scheme-primary-high;
$style-text-sucess: $scheme-on-surface-success;
$style-text-error: $scheme-on-surface-error;
$style-text-primary: $scheme-on-surface-primary;
$style-text-medium: $scheme-on-surface-medium;
$style-text-high: $scheme-on-surface-high;
$style-surface-disabled: $scheme-surface-disabled;
$style-surface-low: $scheme-surface-low;
$style-surface-medium: $scheme-surface-medium;
$style-surface-high: $scheme-surface-high;
$style-background-default: $scheme-background;
$scheme-dimmer-low: rgba($scheme-surface-high, 0.8);
$scheme-dimmer-high: rgba($scheme-background, 0.8);
$style-appbar-page-filled-background: $gradient-background-top;
$style-zoom-bar-background-background: $scheme-dimmer-low;
