@use '../../variables' as vars;

.chip {
  border: solid 2px vars.$style-button-primary-enabled-background;
  padding: 6px 14px;
  border-radius: 18px;
  height: 36px;
  font-size: 14px;
  width: 100%;
  text-align: center;
  cursor: pointer;

  &:hover,
  &--selected {
    background-color: vars.$style-button-primary-enabled-background;
    color: vars.$scheme-on-primary-high;
  }
}
