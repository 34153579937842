@use './variables' as vars;

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  //background-color: vars.$scheme-surface-high;
  background-color: #06070a;

  color: white;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

#root,
body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (min-width: 640px) {
  body {
    background-color: #06070a;
    /* background-image: url('./assets/background_space.png');
    background-position: center;
    animation: fadein2 2s; */
  }
}
