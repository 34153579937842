@use 'sass:map';
@use '../../variables' as vars;

.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1999999 !important;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;

  .dialog {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    background-color: vars.$scheme-surface-high;
    padding: 1.5rem;
    height: 100vh;

    &__title {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      font-family: map.get(vars.$typography-header-600, 'fontFamily');
      font-size: map.get(vars.$typography-header-600, 'fontSize');
      font-weight: map.get(vars.$typography-header-600, 'fontWeight');
      line-height: map.get(vars.$typography-header-600, 'lineHeight');
      letter-spacing: map.get(vars.$typography-header-600, 'letterSpacing');
      //margin-bottom: map.get(vars.$typography-header-600, 'margin-bottom');
    }

    &__content {
      margin-bottom: 1rem;
      height: 100%;
      overflow-y: scroll;
      padding-right: 1rem;
      scrollbar-width: 3px;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px; /* The height is only seen on horizontal scrolling */
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: vars.$scheme-surface-low;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (min-width: 640px) {
  .background {
    height: 100vh;

    .dialog {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-width: 600px;
      border-radius: 1rem;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.45);
      border: 1px solid vars.$scheme-surface-low;
      max-height: 70%;
    }
  }
}
