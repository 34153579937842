.presentation {
  display: none;
}

@media (min-width: 1024px) {
  .presentation {
    height: 623px;
    flex: 1;
    margin: 0 36px 0 36px;
    background: no-repeat url('../../assets/data.png');
    background-position: 50% -20%;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
    }

    padding: 0 0 36px 0;

    p {
      width: 440px;
    }
    animation: fadeincard 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
