@use 'sass:map';
@use '../../variables' as vars;

.iconbutton {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 40px;
  width: 40px;
  background-color: vars.$style-button-default-enabled-background;
  position: relative;
  cursor: pointer;

  box-shadow: vars.$shadow-100;
  border-radius: 8px;

  &:hover {
    background-color: vars.$style-button-default-hovered-background;
    color: vars.$style-button-default-hovered-content;
  }

  &:active {
    background-color: #141c1f;
    color: vars.$style-button-facultative-pressed-content;
    svg {
      path {
        stroke: vars.$style-button-facultative-pressed-content;
      }
    }
  }

  &:disabled {
    color: vars.$style-button-default-disabled-content;
    background-color: vars.$style-button-default-disabled-background;
    cursor: default;
  }

  &--primary {
    background-color: vars.$style-button-primary-enabled-background;
    color: vars.$style-button-primary-enabled-content;
    &:hover {
      background-color: vars.$style-button-primary-hovered-background;
      color: vars.$style-button-primary-hovered-content;
    }
    &:active {
      background-color: vars.$style-button-primary-pressed-background;
      color: vars.$style-button-primary-enabled-content;
    }
    &:disabled {
      background-color: vars.$style-button-primary-disabled-background;
      color: vars.$style-button-primary-disabled-content;
    }
  }

  &--small {
    height: 36px;
    width: 36px;
  }

  &--large {
    height: 56px;
    width: 48px;
  }

  &--loading {
    color: transparent;
    &:disabled,
    &:active {
      color: transparent;
    }
    &::after {
      content: '';
      border-radius: 50%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto;
      position: absolute;
      border: 4px solid vars.$style-background-default;
      border-left-color: vars.$style-progress-indicator-light-indicator;
      border-top-color: vars.$style-progress-indicator-light-indicator;
      animation: buttonDefault-loading-spinner 1s ease infinite;
    }
  }

  &--error {
    color: vars.$style-button-error-enabled-content;
    background-color: vars.$style-button-error-enabled-background;
  }
}

@keyframes buttonDefault-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
