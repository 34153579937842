@use '../../variables' as vars;

.language-changer {
  /* position: fixed;
  top: 0px;
  right: 0px; */
  border-color: vars.$style-button-default-enabled-background;
  //width: 100px;
  padding: 0.5rem;
  border-width: 2px;

  label {
    color: vars.$style-button-default-enabled-content;
    border-color: vars.$style-button-default-enabled-background;
    border-width: 2px;
  }
  select,
  span {
    color: vars.$style-button-default-enabled-content;
    border-color: vars.$style-button-default-enabled-background;
    border-width: 2px;
    background-color: vars.$style-button-default-enabled-background;
    border-radius: 8px;
    font-weight: 700;
    //width: 100px;

    option {
      background-color: vars.$style-surface-high;
      color: vars.$style-button-default-enabled-content;
    }
  }
  select:focus,
  select:active {
    border-color: vars.$style-button-default-enabled-background;
    //width: 100px;
    border-width: 2px;

    outline: 2px solid #8678f4;
  }
  .amplify-selectfield {
    //padding: 0.75rem 1.625rem 0.75rem 1rem;
    //padding: 0.5rem;
    margin: 0;
  }
  div {
    border-color: vars.$style-button-default-enabled-background;
    border-width: 0px;
  }
}
